function configs(labels, datasets) {
  return {
    data: {
      labels,
      datasets: [
        {
          label: datasets.label,
          tension: 0.4,
          borderWidth: 0,
          borderRadius: 4,
          borderSkipped: false,
          backgroundColor: "#fff",
          data: datasets.dataPercentage, // Use percentage data for the chart
          maxBarThickness: 6,
        },
      ],
    },

    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true, // Enable tooltips
          callbacks: {
            label: function (tooltipItem) {
              const percentage = tooltipItem.raw;
              const count = datasets.dataCount[tooltipItem.dataIndex];
              const amount = datasets.dataAmount[tooltipItem.dataIndex]; // Get corresponding count for the same index

              // Customize the tooltip to show percentage and count
              return [
                `${count ? tooltipItem.dataset.label : "Transected amount(%)"}: ${percentage}%`,
                `${count ? `Number of Tran` : "Transected amount(RUB)"}: ${count ? count : amount}`,
              ];
            },
          },
          displayColors: false, // Disable the color box in the tooltip
          backgroundColor: "rgba(0, 0, 0, 0.61)", // Optional: Set the tooltip background color
          titleFont: {
            size: 16,
            family: "Roboto",
            weight: "bold",
          },
          bodyFont: {
            size: 14,
            family: "Roboto",
          },
          bodyColor: "#fff", // Optional: Customize tooltip body text color
          padding: 10, // Optional: Add padding around tooltip content
        },
      },
      interaction: {
        intersect: false,
        mode: "index",
      },
      scales: {
        y: {
          suggestedMax: 100,
          suggestedMin: 0,
          min: 0,
          max: 100,
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            type: "linear",
            minBarLength: 6,
            beginAtZero: false, // Ensure axis starts at 0
            stepSize: 25,
            min: 0,
            max: 100,
            end: 100, // Optional: Set interval steps
            callback: function (val, index) {
              return `${val}%`;
            },
            padding: 15,
            font: {
              size: 14,
              family: "Roboto",
              style: "normal",
              lineHeight: 2,
            },
            color: "#fff", // Adjust text color
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
          },
        },
      },
    },
  };
}

export default configs;
