import React, { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

// @mui material components
import { Tab, Divider, Tabs } from "@mui/material";
import PropTypes from "prop-types";

// React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";

// layouts components
import DateToDate from "layouts/transactions/components/DateToDate";
import FormInput from "layouts/transactions/components/FormInput";
import CompliteCurrently from "layouts/transactions/components/CompliteCurrently";
import CompliteLimit from "layouts/transactions/components/CompliteLimit";

import ConfiguratorRoot from "examples/FilterConfigurator/ConfiguratorRoot";
import CloseBtn from "components/CloseBtn";
import CSVDownloadBtn from "components/CSVDownloadBtn";
import CompliteCommand from "layouts/transactions/components/CompliteCommand";
import { omit, pick } from "lodash";

const FilterConfigurator = (props) => {
  const {
    handleCloseMenu,
    DataCSV,
    openMenu,
    setQuery,
    setFilter,
    setLimit,
    limit,
    downloadLoading,
    filter,
    page,
    setPage,
  } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const statusTabs = ["All", "Completed", "Pending", "Failed", "Stoped"];
  const [status, setStatus] = React.useState(
    searchParams.get("status")
      ? statusTabs.findIndex(
          (status) => status.toLowerCase() === searchParams.get("status").toLowerCase()
        )
      : 0
  );
  const [command, setCommand] = React.useState(searchParams.get("command") || "");
  const [req_acc, setReqAcc] = React.useState(searchParams.get("req_acc") || "");
  const [req_id, setReqId] = React.useState(searchParams.get("req_id") || "");
  const [fio, setFio] = React.useState(searchParams.get("fio") || "");
  const [minAmount, setMinAmount] = React.useState(searchParams.get("minAmount") || "");
  const [maxAmount, setMaxAmount] = React.useState(searchParams.get("maxAmount") || "");
  const [currency, setCurrency] = React.useState(searchParams.get("currency") || "");
  const [dateFrom, setDateFrom] = React.useState(searchParams.get("dateFrom") || "");
  const [dateTo, setDateTo] = React.useState(searchParams.get("dateTo") || "");

  const [values, setValues] = React.useState({
    status: searchParams.get("status")
      ? statusTabs.findIndex(
          (status) => status.toLowerCase() === searchParams.get("status").toLowerCase()
        )
      : 4,
  });
  const changeValues = (key, value) => setValues((prev) => ({ ...prev, [key]: value }));

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator: openMenu }}>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
      >
        <SoftBox>
          <SoftTypography variant="h5">Transaction Filters</SoftTypography>
        </SoftBox>
        <CloseBtn handleCloseMenu={handleCloseMenu} />
      </SoftBox>
      <Divider />
      <SoftBox component="form" pt={1.25} pb={3} px={1}>
        <SoftBox>
          <SoftBox mb={0.5} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Transaction Status
            </SoftTypography>
          </SoftBox>
          <Tabs
            value={status}
            onChange={(___, newValue) => setStatus(newValue)}
            TabIndicatorProps={{
              style: {
                backgroundImage: "linear-gradient(310deg, #f53939, #fbcf33)",
              },
            }}
          >
            {statusTabs.map((tab, index) => (
              <Tab key={index} label={tab === "Pending" ? "NmtCheck" : tab} disableRipple />
            ))}
          </Tabs>
        </SoftBox>
        <SoftBox display="flex" alignItems="center" gap={2}>
          <SoftBox mb={0.5}>
            <SoftBox mb={0.5} ml={0.5}>
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Command
              </SoftTypography>
            </SoftBox>
            <CompliteCommand
              value={command}
              onChangeCompliteValue={(___, newValue) => setCommand(newValue)}
            />
          </SoftBox>
        </SoftBox>
        <FormInput
          name={"req_acc"}
          placeholder={"req_acc"}
          value={req_acc}
          onChange={(e) => setReqAcc(e.target.value)}
        />
        <FormInput
          name={"req_id / pay_uno"}
          placeholder={"req_id / pay_uno"}
          value={req_id}
          onChange={(e) => setReqId(e.target.value)}
        />
        {/* <FormInput
          name={"fio"}
          placeholder={"fio"}
          value={fio}
          onChange={(e) => setFio(e.target.value)}
        /> */}
        <SoftBox mb={0.5}>
          <SoftBox mb={0.5} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Amount to Amount
            </SoftTypography>
          </SoftBox>
          <SoftBox display="flex" alignItems="center" gap={1}>
            <SoftInput
              type="number"
              placeholder={"Min"}
              value={minAmount}
              onChange={(e) => setMinAmount(e.target.value)}
            />
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              -
            </SoftTypography>
            <SoftInput
              type="number"
              placeholder={"Max"}
              value={maxAmount}
              onChange={(e) => setMaxAmount(e.target.value)}
            />
            <CompliteCurrently
              value={currency}
              onChangeCompliteValue={(___, newValue) => setCurrency(newValue)}
            />
          </SoftBox>
        </SoftBox>
        <DateToDate
          dateFrom={dateFrom}
          setDateFrom={setDateFrom}
          dateTo={dateTo}
          setDateTo={setDateTo}
        />
        <Divider />
        <CompliteLimit
          value={limit}
          onChangeCompliteValue={(___, newValue) => setLimit(newValue)}
        />
        <SoftBox display="flex" gap={1} justifyContent="space-between" py={3}>
          <SoftButton
            style={{ width: "100%" }}
            variant="gradient"
            color="warning"
            onClick={() => {
              setQuery({
                queryMeta: {
                  paginate: true,
                  limit: limit,
                  page: 1,
                },
                filterMeta: {
                  // ...pick(values, ["req_acc", "req_id", "type", "command", "currency", "fio"]),
                  ...(req_acc && { req_acc }),
                  ...(req_id && { req_id }),
                  // ...(type && { type }),
                  ...(command && { command }),
                  ...(currency && { currency }),
                  ...(fio && { fio }),
                  ...(statusTabs[status] !== "All" && {
                    status: statusTabs[status].toLocaleLowerCase(),
                  }),
                  ...((minAmount || maxAmount) && {
                    amount: {
                      ...(minAmount && { gte: minAmount }),
                      ...(maxAmount && { lte: maxAmount }),
                    },
                  }),
                  ...((dateFrom || dateTo) && {
                    createdAt: {
                      ...(dateFrom && { gte: dateFrom }),
                      ...(dateTo && { lte: dateTo }),
                    },
                  }),
                },
              });
              setFilter((prev) => !prev);
              setPage(1);
              setSearchParams({
                paginate: true,
                limit: limit,
                page: 1,
                ...omit(values, ["status", "minAmount", "maxAmount", "dateFrom", "dateTo"]),
                // ...pick(values, ["req_acc", "req_id", "type", "command", "currency", "fio"]),
                ...(req_acc && { req_acc }),
                ...(req_id && { req_id }),
                // ...(type && { type }),
                ...(command && { command }),
                ...(currency && { currency }),
                ...(fio && { fio }),
                ...(statusTabs[status] !== "All" && {
                  status: statusTabs[status].toLocaleLowerCase(),
                }),

                ...(minAmount && { minAmount }),
                ...(maxAmount && { maxAmount }),

                ...(dateFrom && { dateFrom }),
                ...(dateTo && { dateTo }),
              });
            }}
          >
            Apply Filters
          </SoftButton>

          <SoftButton
            style={{ width: "100%" }}
            variant="outlined"
            color="error"
            onClick={() => {
              setQuery({
                queryMeta: {
                  paginate: true,
                  limit: limit,
                  page: 1,
                },
              });
              setValues({ status: 3 });
              setFilter((prev) => !prev);
              setStatus(0);
              // setType("");
              setCommand("");
              setReqAcc("");
              setReqId("");
              setFio("");
              setMaxAmount("");
              setMinAmount("");
              setDateFrom("");
              setDateTo("");
              setPage(1);
            }}
          >
            Reset Filters
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </ConfiguratorRoot>
  );
};
FilterConfigurator.defaultProps = {
  handleCloseMenu: () => {},
  handleOpenMenu: () => {},
};

// Typechecking props for the FilterConfigurator
FilterConfigurator.propTypes = {
  handleCloseMenu: PropTypes.any,
  handleOpenMenu: PropTypes.any,
  downloadLoading: PropTypes.bool,
  openMenu: PropTypes.bool,
  setQuery: PropTypes.any,
  page: PropTypes.any,
  setFilter: PropTypes.any,
  setPage: PropTypes.any,
  DataCSV: PropTypes.any,
  setLimit: PropTypes.any,
  limit: PropTypes.any,
  filter: PropTypes.bool,
};

export default FilterConfigurator;
