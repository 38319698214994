import React, { useState } from "react";
import { useSoftUIController } from "context";
import * as moment from "moment";
import * as qs from "qs";

import { Card, Icon, IconButton, Tooltip } from "@mui/material";
// example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Table from "examples/Tables/Table";

// components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import transactionsTableData from "layouts/transactions/data/transactionsTableData";
import { getAllTransactions } from "http/transactionApi";
import { useQuery } from "react-query";
import FilterConfigurator from "examples/FilterConfigurator";
import { useNavigate, useSearchParams } from "react-router-dom";
import { formatNumberWithCommas } from "utils";
import { omit } from "lodash";
import CSVDownloadBtn from "components/CSVDownloadBtn";

const Transactions = () => {
  const [controller, dispatch] = useSoftUIController();
  const { userRole } = controller;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { skeletonRows, columns } = transactionsTableData();
  const [openMenu, setOpenMenu] = useState(false);
  const [page, setPage] = useState(+searchParams.get("page") || 1);
  const [limit, setLimit] = useState(searchParams.get("limit") ?? 10);
  const [filter, setFilter] = useState(false);

  const [query, setQuery] = useState({
    queryMeta: {
      paginate: true,
      limit: searchParams.get("limit") || limit,
      page: searchParams.get("page") || 1,
    },

    filterMeta: {
      // ...pick(values, ["req_acc", "req_id", "type", "command", "currency", "fio"]),
      ...(searchParams.get("req_acc") && { req_acc: searchParams.get("req_acc") }),
      ...(searchParams.get("req_id") && { req_id: searchParams.get("req_id") }),
      // ...(type && { type }),
      ...(searchParams.get("command") && { command: searchParams.get("command") }),
      ...(searchParams.get("currency") && { currency: searchParams.get("currency") }),
      ...(searchParams.get("fio") && { fio: searchParams.get("fio") }),
      ...(searchParams.get("status") && {
        status: searchParams.get("status") === "nmtCheck" ? "pending" : searchParams.get("status"),
      }),
      ...((searchParams.get("req_acc") || searchParams.get("req_acc")) && {
        amount: {
          ...(searchParams.get("minAmount") && { gte: searchParams.get("minAmount") }),
          ...(searchParams.get("maxAmount") && { lte: searchParams.get("maxAmount") }),
        },
      }),
      ...((searchParams.get("req_acc") || searchParams.get("req_acc")) && {
        createdAt: {
          ...(searchParams.get("dateFrom") && { gte: searchParams.get("dateFrom") }),
          ...(searchParams.get("dateTo") && { lte: searchParams.get("dateTo") }),
        },
      }),
    },
  });

  console.log(limit, "asdasdasdasd");

  // updateOneTransaction
  const handleCloseMenu = () => setOpenMenu(false);
  const handleOpenMenu = () => setOpenMenu(true);

  const { isLoading: dataLoading, data: transactions } = useQuery(
    ["transactions", page, filter],
    () => getAllTransactions(qs.stringify(query))
  );

  const { isLoading: downloadLoading, data: transactions2 } = useQuery(
    ["transactions2", page, filter],
    () => getAllTransactions(qs.stringify(omit(query, ["queryMeta"])))
  );

  const transactiondTableRowData = transactions?.data.map((transaction) => ({
    command: transaction.command,
    amount: (
      <SoftTypography component="span" variant="body2">
        {formatNumberWithCommas(+transaction?.amount)} {transaction?.currency}
      </SoftTypography>
    ),
    pay_uno: transaction.pay_uno || "------------",
    req_acc: transaction.req_acc,
    req_id: transaction.req_id,
    fio: transaction.fio || "------------",
    type: transaction.type,
    status: (
      <SoftTypography
        component="span"
        variant="body2"
        fontWeight="light"
        color={
          (transaction.status === "completed" && "success") ||
          (transaction.status === "failed" && "error") ||
          "text"
        }
      >
        {transaction.status === "pending" ? "nmtCheck" : transaction.status}
      </SoftTypography>
    ),
    ["started At"]: moment(transaction.createdAt).format("DD.MM.YYYY_HH:mm:ss"),
    ["last activity"]: moment(transaction.updatedAt).format("DD.MM.YYYY_HH:mm:ss"),
    actions: userRole === "superAdmin" && (
      <SoftBox display="flex" alignItems="center" gap={1.5} sx={{ cursor: "pointer" }}>
        <Tooltip title="Transaction Details">
          <Icon
            fontSize={"15px"}
            sx={{ color: "#fab134" }}
            onClick={() => navigate(`/transactions/${transaction.uuid}`)}
          >
            credit_score_icon
          </Icon>
        </Tooltip>
        {/* <TransactionDetails uuid={transaction.uuid} /> */}
      </SoftBox>
    ),
  }));

  // Data for filters
  const DataCSVFilters = [
    {
      req_acc: query?.filterMeta?.req_acc || "",
      req_id: query?.filterMeta?.req_id || "",
      command: query?.filterMeta?.command || "",
      currency: query?.filterMeta?.currency || "",
      fio: query?.filterMeta?.fio || "",
      status:
        query?.filterMeta?.status === "pending" ? "nmtCheck" : query?.filterMeta?.status || "",
      minAmount: query?.filterMeta?.amount?.gte || "",
      maxAmount: query?.filterMeta?.amount?.lte || "",
      dateFrom: query?.filterMeta?.createdAt?.gte || "",
      dateTo: query?.filterMeta?.createdAt?.lte || "",
    },
  ];

  const DataCSV = Array.isArray(transactions2?.data)
    ? transactions2?.data?.map((transaction) => ({
        uuid: transaction.uuid,
        status: transaction?.status === "pending" ? "nmtCheck" : transaction?.status || "",
        pay_p:
          transaction?.details?.foraPayment?.request || ""
            ? transaction?.details?.foraPayment?.request?.pay_p || ""
            : transaction?.details?.foraPayment?.response?.pay_p || "",
        rec_ssn: transaction?.details?.tpmNthCheck?.response
          ? transaction?.details?.tpmNthCheck?.response.rec_ssn || ""
          : transaction?.details?.tpmNthCheck?.rec_ssn || "",
        amount: `${transaction?.amount || ""} ${transaction?.currency || ""}`,
        createdAt: moment(transaction.createdAt).format("DD.MM.YYYY_HH:mm:ss") || "",
      }))
    : [];

  // Define headers for the filter data and transaction data
  const filterHeaders = [
    "req_acc",
    "req_id / pay_uno",
    "command",
    "currency",
    "status",
    "minAmount",
    "maxAmount",
    "dateFrom",
    "dateTo",
  ];
  const transactionHeaders = ["uuid", "status", "pay_p", "rec_ssn", "amount", "createdAt"];

  // Combine both tables with a separator row between them
  const combinedData = [
    filterHeaders,
    ...DataCSVFilters?.map((item) => [
      item.req_acc,
      item.req_id,
      item.command,
      item.currency,
      item.status,
      item.minAmount,
      item.maxAmount,
      item.dateFrom,
      item.dateTo,
    ]),
    ["", "", "", "", "", "", "", "", "", ""], // Separator row
    transactionHeaders,
    ...DataCSV?.map((item) => [
      item.uuid,
      item.status,
      item.pay_p,
      item.rec_ssn,
      item.amount,
      item.createdAt,
    ]),
  ];

  console.log(transactions2?.data, "asdhasdghuiasghuidhasuhd");

  return (
    <DashboardLayout>
      <FilterConfigurator
        downloadLoading={downloadLoading}
        filter={filter}
        setLimit={setLimit}
        limit={limit}
        DataCSV={DataCSV}
        page={page}
        setPage={setPage}
        setQuery={setQuery}
        setFilter={setFilter}
        handleCloseMenu={handleCloseMenu}
        handleOpenMenu={handleOpenMenu}
        openMenu={openMenu}
      />
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h4" color="warning" fontWeight="bold" textGradient>
                Fora to EasyPay Transactions
              </SoftTypography>
              <SoftBox display="flex" justifyContent="center">
                <CSVDownloadBtn downloadLoading={downloadLoading} datas={combinedData || []} />
                <Tooltip placement="top" title="Filters">
                  <IconButton size="large" onClick={handleOpenMenu} color="warning">
                    <Icon sx={{ fontWeight: "bold" }}>filter_list_icon</Icon>
                  </IconButton>
                </Tooltip>
                {/* <IconButton onClick={handleOpenMenu} size="large" color="warning">
                  <Icon sx={{ fontWeight: "bold" }}>filter_list_icon</Icon>
                </IconButton> */}
              </SoftBox>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table
                minHeight={limit == 20 ? 1180 : limit == 5 ? 500 : 660}
                pagination
                pageCount={Math.ceil(transactions?.meta?.count / limit)}
                setPage={setPage}
                currentPage={page}
                setQuery={setQuery}
                limit={limit}
                handleOpenFilterMenu={handleOpenMenu}
                columns={columns}
                rows={dataLoading ? skeletonRows : transactiondTableRowData}
              />
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
};

export default Transactions;
